<template>
  <div dir="ltr" class="h-100">
    <div class="d-flex flex-column h-100" style="padding:0 2% 0 1.5%;">
      <div class="d-flex align-items-center justify-content-between">
        <p class="m-0 TitleTop">Installed Integrations</p>
      </div>

      <div
        class="flex-grow-1"
        style="
          max-height: calc(100vh - 125px);
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <div class="mt-3 mb-2">
          <div class="d-flex flex-wrap">
            <button
              class="btn categoryBtns"
              v-for="category in categoryList"
              :key="category.id"
              :class="selectedCategoryId == category.id ? 'selected' : null"
              @click="updateSelectedCategoryId(category.id)"
            >
              {{ category.title }}
            </button>
          </div>
        </div>
        <div v-if="isLoading">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
              v-for="index in 3"
              :key="index"
            >
              <AllIntegrationSkeleton />
            </div>
          </div>
        </div>

        <div
          v-else
          :style="
            !getConnectedIntegrations.length
              ? 'display:flex;align-items:center;justify-content:center;height:80%'
              : null
          "
        >
          <div class="mt-4" v-if="getConnectedIntegrations.length">
            <div class="row InstalledIntegration-main">
              <div
                class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                v-for="(integration, index) in getConnectedIntegrations"
                :key="index"
                style="cursor: pointer"
              >
                <div
                  class=""
                  style="
                    border-radius: 16px;
                    border: 1px solid rgba(237, 237, 237, 1);
                    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
                  "
                >
                  <div class="card-section p-4">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center" style="gap: 20px">
                        <div
                          class="img"
                          style="width: 48px; height: 48px; border-radius: 14px"
                        >
                          <LazyImage
                            :src="`${integration.image}`"
                            :key="integration.image"
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 14px;
                            "
                            :alt="integration.title"
                            :title="integration.title"
                            class="rounded"
                          />
                        </div>
                        <div class="integration-heading text-left">
                          <p class="m-0">{{ integration.title }}</p>
                        </div>
                      </div>
                      <div
                        class="actionDiv d-flex align-items-center"
                        v-if="
                          !nonAuthIntegrations.includes(integration.handle_key)
                        "
                      >
                        <div class="show-logo custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`${integration.handle_key}`"
                            v-model="integration.status"
                            style="cursor: pointer"
                            @change="
                              toggleStatus($event, integration.connection_id)
                            "
                          />
                          <label
                            class="custom-control-label"
                            :for="`${integration.handle_key}`"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div class="integration-description mt-3 text-left">
                      <p class="m-0">{{ integration.short_description }}</p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-end px-4 py-3"
                    style="border-top: 1px solid rgba(237, 237, 237, 1)"
                  >
                    <div class="" >
                      <button class="view-btn btn"  @click.stop="openIntegration($event, integration.app_id)">View integration</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center" v-else>
            <div
              class="card"
              style="
                background: #b3afb614;
                max-width: 500px;
                border-radius: 16px;
                border: 1px solid #ededed;
              "
            >
              <div class="card-body">
                <div
                  class="d-flex justify-content-center flex-column align-items-center"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="4"
                      y="4"
                      width="56"
                      height="56"
                      rx="28"
                      fill="#EADDF6"
                    />
                    <rect
                      x="4"
                      y="4"
                      width="56"
                      height="56"
                      rx="28"
                      stroke="#F4EFF9"
                      stroke-width="8"
                    />
                    <path
                      d="M31 24C31 22.8954 31.8954 22 33 22C34.1046 22 35 22.8954 35 24V25C35 25.5523 35.4477 26 36 26H39C39.5523 26 40 26.4477 40 27V30C40 30.5523 39.5523 31 39 31H38C36.8954 31 36 31.8954 36 33C36 34.1046 36.8954 35 38 35H39C39.5523 35 40 35.4477 40 36V39C40 39.5523 39.5523 40 39 40H36C35.4477 40 35 39.5523 35 39V38C35 36.8954 34.1046 36 33 36C31.8954 36 31 36.8954 31 38V39C31 39.5523 30.5523 40 30 40H27C26.4477 40 26 39.5523 26 39V36C26 35.4477 25.5523 35 25 35H24C22.8954 35 22 34.1046 22 33C22 31.8954 22.8954 31 24 31H25C25.5523 31 26 30.5523 26 30V27C26 26.4477 26.4477 26 27 26H30C30.5523 26 31 25.5523 31 25V24Z"
                      stroke="#4D1B7E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="integration-heading mt-2">
                  No Integrations found
                </div>
                <div class="integration-description my-2">
                  You do not currently have any integrations connected. Click
                  the button below to add the Integration for your account
                </div>
                <div class="mt-4 mb-2 text-center">
                  <router-link to="/integrations/list" class="goToIntegrations"
                    >Add more integrations</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UpgradePlanModal
      ref="UpgradeUserPlanModalIntegration"
      :title="upgradeModalTitle"
      :subText="upgradeModalsubText"
      @openPlansModalChild="openShopifyPlansModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AllIntegrationSkeleton from "../../Integrations/AllIntegrationSkeleton.vue";
import axios from "axios";
import UpgradePlanModal from "../../Dashboard/modal/UpgradePlanModal.vue";

export default {
  components: {
    AllIntegrationSkeleton,
    UpgradePlanModal,
  },
  data() {
    return {
      categoryList: [
        { id: 11, title: "All" },
        { id: 1, title: "Marketing Automation" },
        { id: 2, title: "Review Management" },
        { id: 3, title: "Payment" },
        { id: 4, title: "E-commerce" },
        { id: 6, title: "Integration Platform" },
        { id: 7, title: "Marketing & Sales" },
      ],
      selectedCategoryId: 11,
      upgradeModalTitle: "Upgrade your plan to access the Integration.",
      upgradeModalsubText: `Your current Plan doesn't support the Integration. Please upgrade your account.`,
      QuizID: "",
      QuizKey: "",
      Integration: [],
      isLoading: false,
      nonAuthIntegrations: ["shopify", "wix", "bc"],
      shopifyObj: {
        app_id: 13,
        integration_category_id: 4,
        plan_type_id: 0,
        title: "Shopify",
        image: "https://images.quizell.com/website%2Fshopify.svg",
        description:
          '<div style="font-family: \'Poppins\', sans-serif;">\r\n  <h4 style="font-weight: bold;">Quizell and Shopify Integration</h4>\r\n  <p>Quizell integrates with Shopify, the leading e-commerce platform, to bring a powerful solution for online stores looking to personalize product recommendations for their customers. The integration allows stores to collect valuable customer information, such as preferences and buying habits, through product recommendation quizzes and use it to recommend the right products to each customer. </p>\r\n  <h4 style="font-weight: bold;">Personalized Product Recommendations</h4>\r\n  <p>By leveraging the power of product recommendation quizzes, online stores can improve the customer experience and drive sales. Personalized product recommendations based on customer quiz results result in higher conversion rates, as customers are more likely to purchase products that are specifically tailored to their needs and interests. </p>\r\n  <h4 style="font-weight: bold;">A Seamless Solution</h4>\r\n  <p>With the integration between Quizell and Shopify, online stores can enjoy a seamless solution for collecting customer data and using it to drive sales and engagement. Whether you\'re looking to improve the customer experience, increase conversion rates, or boost sales, Quizell and Shopify have got you covered. </p>\r\n</div>',
        help_link: "https://support.quizell.com/",
        video_link: "",
        handle_key: "shopify",
        data_order: 0,
        status: 1,
        created_at: "2023-01-12T14:54:54.000000Z",
        updated_at: "2023-01-12T14:54:54.000000Z",
        short_description:
          "Quizell integrates with Shopify to personalize product recommendations for customers and increase sales for online stores",
        permissions: "read products, update theme",
        is_oauth: 0,
        oauth_url: null,
        app_fields: null,
        play_store_link:
          "https://apps.shopify.com/quizell-product-recommendation",
        plan_type_name: "Free",
        connection_id: 0,
        installed: "no",
      },
      wixObj: {
        app_id: 14,
        integration_category_id: 4,
        plan_type_id: 0,
        title: "Wix",
        image: "https://images.quizell.com/website%2FWix.svg",
        description:
          '<div style="font-family: \'Poppins\', sans-serif;">\r\n  <h4 style="font-weight: bold;">Quizell and Wix Integration</h4>\r\n  <p>Quizell integrates with Wix, the popular website builder, to provide businesses with a simple and effective solution for adding quizzes and product recommendations to their Wix websites. The integration allows businesses to capture valuable customer information and use it to drive sales and improve the customer experience.</p>\r\n  <h4 style="font-weight: bold;">Add Quizzes to Wix Websites</h4>\r\n  <p>The integration between Quizell and Wix enables businesses to easily add quizzes to their Wix websites. Quizzes are an effective tool for capturing customer data and using it to make product recommendations, and the integration allows businesses to add quizzes to their Wix websites without any coding or technical expertise required. </p>\r\n  <h4 style="font-weight: bold;">Improve Customer Experience</h4>\r\n  <p>By integrating Quizell with Wix, businesses can use quizzes to provide customers with personalized product recommendations based on their interests and needs. This leads to a better customer experience, increased customer engagement, and ultimately, increased sales. </p>\r\n</div>',
        help_link: "https://www.support.quizell.com",
        video_link: "",
        handle_key: "wix",
        data_order: 1,
        status: 1,
        created_at: "2023-01-12T14:54:54.000000Z",
        updated_at: "2023-01-12T14:54:54.000000Z",
        short_description:
          "Quizell integrates with Wix to add quizzes and product recommendations to Wix websites.",
        permissions: null,
        is_oauth: 0,
        oauth_url: null,
        app_fields: null,
        play_store_link: "https://www.wix.com/app-market/quizell-product-quiz",
        plan_type_name: "Free",
        connection_id: 0,
        installed: "no",
      },
      BcObj: {
        app_id: 17,
        integration_category_id: 4,
        plan_type_id: 0,
        title: "BigCommerce",
        image: "https://images.quizell.com/website%2FGroup%2069213.svg",
        description:
          "<h2>Quizell and BigCommerce Integration</h2>\r\n<p>Quizell has created an app for the popular e-commerce platform, BigCommerce, that allows for a seamless integration between the two. With this integration, all of your BigCommerce products can be directly imported into Quizell, making it easier for you to manage your store and products. </p>\r\n<p>Gone are the days of manually entering product information into both platforms and worrying about inconsistencies or errors. The integration between Quizell and BigCommerce saves you time and provides peace of mind, knowing that your products are up-to-date and accurate. </p>\r\n<p>Not only does the integration simplify your store management, but it also allows you to take advantage of Quizell's powerful e-commerce features, such as product quizzes, personalized recommendations, and more. These features have been proven to increase sales and provide a better customer experience for your shoppers. </p>\r\n<p>Getting started with the Quizell and BigCommerce integration is easy. Simply install the Quizell app from the BigCommerce App Store and follow the steps to connect your store. </p>\r\n<h3>Unlock the full potential of your e-commerce store with the Quizell and BigCommerce integration.</h3>",
        help_link: "https://www.quizell.com",
        video_link: "https://www.youtube.com/watch?v=t0Q2otsqC4I",
        handle_key: "bc",
        data_order: 9,
        status: 1,
        created_at: "2023-02-09T16:33:37.000000Z",
        updated_at: "2023-02-09T16:33:42.000000Z",
        short_description:
          "Quizell integrates with Bigcommerce to recommend products based on customer quiz results, driving sales and engagement for online stores.",
        permissions: null,
        is_oauth: 0,
        oauth_url: null,
        app_fields: null,
        play_store_link:
          "https://www.bigcommerce.com/apps/quizell-product-quiz/",
        plan_type_name: "Free",
        connection_id: 0,
        installed: "no",
      },
    };
  },
  methods: {
    updateSelectedCategoryId(id) {
      this.selectedCategoryId = id;
    },
    openShopifyPlansModal() {
      this.$refs.PlansModalRefDetailIntegration.OpenModal();
    },
    async isIntegrationAllowed(id) {
      try {
        let data = {
          app_id: id,
          quizId: parseInt(localStorage.getItem("QuizID")),
        };
        const response = await axios.post(`/getIntegrationAllowed`, data);
        if (
          response.data.ok &&
          response.data.message == "Integration is allowed."
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      }
    },
    async openIntegration(event, id) {
      let allowedTarget = ["DIV", "P", "IMG","BUTTON"];
      if (allowedTarget.includes(event.target.tagName)) {
        let isAllowed = true;
        // await this.isIntegrationAllowed(id)
        if (isAllowed) {
          this.$router.push(`/integrations/${id}`);
        } else {
          this.$refs.UpgradeUserPlanModalIntegration.OpenModal();
        }
      } else {
        return;
      }
    },
    ...mapMutations(["setQuizKey"]),
    async toggleStatus(e, id) {
      if (id) {
        let QuizID = [];
        QuizID.push(JSON.parse(localStorage.getItem("QuizID")));
        try {
          let data = {
            connection_id: id,
            quiz_id: QuizID,
          };
          if (e.target.checked) {
            data.status = true;
          } else {
            data.status = false;
          }
          const response = await axios.post(
            `/integrationConnectionQuizSave`,
            data
          );
          if (response.status == 200) {
            this.$toasted.show("Success", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });

            this.GetIntegrations();
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        }
      } else {
        this.$toasted.show("Something went wrong", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },

    async GetIntegrations() {
      this.isLoading = true;
      try {
        let data = {
          quizId: JSON.parse(localStorage.getItem("QuizID")),
        };
        const response = await axios.post(`/loadIntegrationConnections`, data);
        if (response.status == 200 && response.data.status == "success") {
          let nonAuthObj = this.getNonAuthObj(this.getUserType);
          if (nonAuthObj) {
            this.Integration = [
              { ...nonAuthObj },
              ...response.data.data.integrationsList,
            ];
          } else {
            this.Integration = response.data.data.integrationsList;
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    getNonAuthObj(userType) {
      if (userType == "shopify") return this.shopifyObj;
      if (userType == "wix") return this.wixObj;
      if (userType == "bc") return this.BcObj;
      return null;
    },
  },

  computed: {
    ...mapGetters(["getQuizID", "getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    getConnectedIntegrations() {
      if (this.selectedCategoryId == 11) return this.Integration;
      return this.Integration.filter(
        (integration) =>
          integration.integration_category_id == this.selectedCategoryId
      );
    },
  },
  mounted() {
    this.QuizID = this.getQuizID;
    this.GetIntegrations();
  },
  created() {
    this.QuizKey = this.$route.params.id;
    this.setQuizKey(this.QuizKey);
  },
};
</script>

<style scoped>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}
.categoryBtns {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #73738d;
  padding-bottom: 10px;
}
.categoryBtns.selected {
  border-radius: 0 !important;
  color: #4d1b7e;
  border-bottom: 1px solid #4d1b7e;
}
.view-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(77, 27, 126, 1);
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
}

.goToIntegrations,
.goToIntegrations:hover {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  background-color: #4d1b7e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  text-align: center;
}

.integration-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(16, 24, 40, 1);
}
.integration-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(102, 112, 133, 1);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
